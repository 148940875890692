<template>
  <div id="CertifiedMember">
    <!-- <van-overlay :show="showOver" @click="showOver = false" /> -->
    <img class="header" src="img/toVip.jpg" />
    
    <div class="main" @click="toBuy">
      <img class="header" src="img/button1.jpg" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CertifiedMember',
   data(){
    return{
      inviteAward:'',
      showOver:false,
      showWX:false,
      product_id:'',
      isPay:false
    }
  },
  mounted(){

  },
  created(){
  
  },
  methods:{
    // 点击购买
    toBuy(){
     this.axios({
        method: 'GET',
        url: '/vip/getApproveVip',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.product_id = res.data.data.product.id
          this.prepay()
        }
      })
    },
    prepay(){
      if( !this.isPay ){
        this.isPay = 1
        this.axios({
          method: 'POST',
          url: '/payment/prepay',
          headers: {
            'Authorization': `Bearer ${this.Global.getCookie('token')}` 
          },
          data: {
            productId: this.product_id,
            transmit:''
          }
        }).then( (res) => {
          if(res.data.code == 0){
            if(res.data.data.redirect){
              //免费
              this.$toast('购买成功！')
              setTimeout(() => {
                this.$router.push('/SignUpEnd?type=c&activityId='+this.activityId)
              }, 1000)
            }else{
              this.callPay(res.data.data)
            }
          }else{
            this.isPay = 0
            this.$toast(res.data.message)
          }
        })
      }
    },
    // 微信调起支付
    callPay(res){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res)); 
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res));
        }
      }else{
        this.onBridgeReady(res);
      }
    },
    onBridgeReady(data){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', data.config,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast('购买成功！')
            this.$router.push('/Home?showQrCode=1')
          }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.isPay = 0
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.isPay = 0
            this.$toast('支付失败')
          }
      }); 
    },
  }
}
</script>
<style lang="less" scoped>
#CertifiedMember{
  position: relative;
  .main{
    position: fixed;
    padding: 0 0.15rem;
    bottom: 0.15rem;
  }
  .header{
    width: 100%;
  }
}
</style>